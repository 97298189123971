import React, { PropsWithChildren, ReactNode } from 'react';
import { vdBodyTypesPath } from '../../lib/api/Api';
import { MethodData, useAxios } from '../../lib/custom-axios/CustomAxios';
import Footer from './footer/Footer';
import style from './marketing-layout.module.scss';

const MarketingLayout: React.FC<PropsWithChildren<{}>> = ({children}) => {
  const {data: bodyTypes} = useAxios(vdBodyTypesPath, {
    method: MethodData.GET
  })
  return (
    <div className={style.marketingSite}>
      {children}
      <Footer/>
    </div>
  )
}

export default MarketingLayout;