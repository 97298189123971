import React from 'react';
import { Col } from 'react-bootstrap';
import Link from '../../../lib/link/Link';

import Favorite from '../../../lib/favorite/Favorite';
import { IListing } from '../../../lib/interfaces/marketPlace/marketplace';
import { listingLocationIconWhite, marketplaceOffersIcon, speedometerIcon } from '../../../lib/svg-icons/SvgIcons';
import { formatCurrency } from '../../../lib/currency-formatter/CurrencyFormatter';
import { getPhotoThumbnailPath } from '../../../lib/utils';
import { formatMileageByKilometer } from '../../../lib/currency-formatter/MileageFormatter';
import VehicleImg from '../../../lib/vehicle-img/VehicleImg';

interface IListingItemProps {
    listing: IListing;
    latLng?: { lat: number; lng: number };
    showSoldFavorite?: boolean;
}

const ListingItem: React.FC<IListingItemProps> = ({listing, latLng, showSoldFavorite = false}) => {
    const getListingStatusBadge = () => {
        if (listing?.isSold) {
            return (
                <span className="sold-badge">
                    Sold
                </span>
            );
        }
        if (listing?.conditionallySold) {
            return (
                <span className="sale-in-progress-badge">
                    Sale in progress
                </span>
            );
        }
        if (listing?.myPurchaseIntentId) {
            return (
                <span className="dark-bg-badge">
                    You're negotiating
                </span>
            );
        }
        if (listing?.offersCount) {
            return (
                <span className="dark-bg-badge">
                   <span
                       className="d-flex aliign-items-center me-2">{marketplaceOffersIcon}</span>{listing?.offersCount}{listing?.offersCount > 1 ? ' offers' : ' offer'}
                </span>
            );
        }
        return null;
    };

    return (
        <Col sm={6} lg={4} xl={4} className={'listing-item'} data-testid={'listing-item'}>
            <Link legacyBehavior href={`/marketplace/detail/${listing.id}`}>
                <a className={'no-underline'}>
                    <div className="listing-item-card">
                        <div className="status-row">
                            <div className="badges px-4">
                                {getListingStatusBadge() && <div>{getListingStatusBadge()}</div>}
                                {
                                    (showSoldFavorite || !listing?.isSold) ? (
                                        <Favorite id={listing.id}/>
                                    ) : null
                                }
                            </div>
                        </div>
                        <div className={'photo-container'} style={{
                            opacity: listing.isSold ? 0.5 : 1
                        }}>
                            <VehicleImg src={getPhotoThumbnailPath(listing.publishedVersion?.photoPath, 550, 300)}
                                        alt="Vehicle img"/>
                        </div>
                        <div className={'listing-details'}>
                            <div className="listing-details-title">{listing?.vehicleName}</div>
                            <div className="listing-details-price-mileage">
                                <span className={'me-3'}>{formatCurrency(listing.publishedVersion?.price)}</span>
                                <div className="d-flex">
                                    <span className="d-flex align-items-center me-1">{speedometerIcon}</span>
                                    <span>{formatMileageByKilometer(listing.publishedVersion?.mileage)}</span>
                                </div>
                            </div>
                            <div className="listing-details-location">
                                <span>
                                    {listingLocationIconWhite}
                                </span>
                                <span className={'ms-2'}>
                                    {listing.publishedVersion?.dealer?.city}, {listing.publishedVersion?.dealer?.province?.abbreviation} {listing.publishedVersion?.dealer?.distance ? `(${formatMileageByKilometer(listing.publishedVersion.dealer.distance)})` : null}
                                </span>
                            </div>
                        </div>
                    </div>
                </a>
            </Link>
        </Col>
    );
};

export default ListingItem;
