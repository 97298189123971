export const formatMileageByKilometer = (value = 0, showByNumber = false, prefix = '', suffix = 'km', language = 'en-US') => {
    const formatter = new Intl.NumberFormat(language, {
        style: 'decimal',
        // style: 'unit',
        // unit: 'kilometer',
        maximumFractionDigits: 1
    });

    return `${value < 0 ? '-' : ''}${prefix || ''}${value > 0.5 ? `${formatter.format(Math.abs(value))} ${suffix}` : (showByNumber ? `${value} ${suffix}` : 'Near You')}`.trim();
};
