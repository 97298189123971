import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import styles from './favorite.module.scss';
import { updateFavoriteByIdPath } from '../api/Api';
import { useLoggedIn } from '../user-logged-in/UserLoggedIn';
import { MethodData, useAxios } from '../custom-axios/CustomAxios';
import { favoriteBlackIcon, favoriteWhiteIcon, heartIcon } from '../svg-icons/SvgIcons';
import createToast from '../toast/CreateToast';
import { ToastBg } from '../toast/NotificationToast';
import { addUserFavorite, removeUserFavorite } from '../reducer/user/UserReducerDispatch';
import { IFavorite, UserContext, UserType } from '../../contexts/user/UserContext';
import { useRouter } from 'next/router';
import { sendGTMEvent } from '@next/third-parties/google'

interface IFavoriteProps {
    id: number;
    isButton?: boolean;
}

const Favorite: React.FC<IFavoriteProps> = ({id, isButton = true}) => {
    const router = useRouter();
    const {isLoggedIn} = useLoggedIn();
    const {user, dispatch} = useContext(UserContext);
    const [isFavorite, setIsFavorite] = useState<boolean>(!!user?.favorites?.find(favItem => favItem.listingId === id) || false);
    const {loading: favoriteLoading, fetch: updateFav} = useAxios();

    useEffect(() => {
        user && setIsFavorite(!!user?.favorites?.find(favItem => favItem.listingId === id) || false);
    }, [user]);

    return (
        isButton ? (
            <Button
                className={`${styles.favorites} standardButton favorite-button ${isFavorite ? 'is-favorite' : ''}`}
                size="sm"
                disabled={user?.type === UserType.DEALER}
                onClick={updateFavorite}>
                {isFavorite ? favoriteWhiteIcon : favoriteBlackIcon}
            </Button>
        ) : (
            <div className={`${styles.cardFavoriteButton} ${isFavorite ? 'is-favorite' : ''}`}
                 style={{opacity: user?.type === UserType.DEALER ? 0.5 : 1}}
                 onClick={updateFavorite}>
                <span className="footer-icon">{heartIcon}</span>
                <span className={'ms-2'}>{isFavorite ? 'Saved' : 'Save'}</span>
            </div>
        )
    );

    function updateFavorite(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        event.preventDefault();

        if (!user) {
            router.push(`/signin`);
            return;
        }
        if (user?.type === UserType.DEALER) return;

        if (isLoggedIn) {
            if (!isFavorite) {
                sendGTMEvent({ event: 'conversion_event_default_1'})
            }

            !favoriteLoading && updateFav(updateFavoriteByIdPath(id), {
                method: isFavorite ? MethodData.DELETE : MethodData.POST
            }).then((favorite: IFavorite) => {
                dispatch?.(isFavorite ? removeUserFavorite(id) : addUserFavorite(favorite));
                setIsFavorite(!isFavorite);
                createToast({
                    bg: ToastBg.Success,
                    content: !isFavorite ? `Vehicle has been added to your favorites` : 'Vehicle has been removed from your favorites'
                });
            });
        } else {
            createToast({
                bg: ToastBg.Warning,
                content: `Please login to save this listing.`
            });
        }
    }
};

export default Favorite;
