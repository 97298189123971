import useLottie from 'lottie-react';

const AnimatedComponent: React.FC<{ path: string }> = ({path}) => {
    const options: any = {
        loop: true,
        autoplay: true,
        path: path
    };

    const View = useLottie(options);
    return (
        <div style={{pointerEvents: 'none'}}>
            {View}
        </div>
    )
}

export default AnimatedComponent;
